



















import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { ApiResource } from '@/types';
import ChatComponent from '@/components/chat/Component.vue';

interface VuexBindings {
  findCommission: (COMMISSION_ID: string) => ApiResource.Commission | undefined,
  listMessages: (COMMISSION_ID: string) => ApiResource.CommissionMessage[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    ChatComponent,
  },
  data() {
    return {
      error: null as Error|null,
      loading: false,
      more: true,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      findCommission: 'commission/Find',
      listMessages: 'commission/message/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    commission(): ApiResource.Commission | undefined {
      return this.findCommission(this.COMMISSION_ID);
    },
    messages(): ApiResource.CommissionMessage[] {
      if (!this.commission) return [];
      return this.listMessages(this.COMMISSION_ID)
        .sort((a: ApiResource.CommissionMessage, b: ApiResource.CommissionMessage) => {
          // Latest to bottom
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });
    },
    oldestMessage(): ApiResource.CommissionMessage | null {
      return this.messages[0] || null;
    },
  },
  methods: {
    async loadOlderMessages() {
      if (!this.oldestMessage) return;

      this.loading = true;

      const count = this.messages.length;

      await this.$store.dispatch('commission/message/List', {
        COMMISSION_ID: this.COMMISSION_ID,
        query: {
          before_id: this.oldestMessage.id,
          limit: 20,
        },
      }).catch((e: AxiosError) => { this.error = e; });

      this.loading = false;

      this.more = count < this.messages.length;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
